
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// normalize styles for consistency across browsers
@import 'normalize.css';

// import colors
@import './assets/styles/colors';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$OS-FSX-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$OS-FSX-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$OS-FSX-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$FSX-theme: mat.m2-define-light-theme((
  color: (
    primary: $OS-FSX-primary,
    accent: $OS-FSX-accent,
    warn: $OS-FSX-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($FSX-theme);

// import fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html,
body {
  height: 100%;
}

body {  
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  margin: 0;
  z-index: 1;
}

.body-background {
  background-clip: padding-box;
  background-image: url('/assets/images/X.png');
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

header, footer {
  padding: 1rem;

  @media screen and (min-width: 960px) {
    padding: 1.375rem 3.125rem;
  }
}

footer {
  background: #fff;

  .footer-icon {
    height: 13px;
    margin-right: 2px;
    vertical-align: middle;
    width: 10px;
  }

  border-top: 1px solid #ededed;
}

main {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 2rem auto 6rem;
}

// Typography

h1 {
  color: $FSX-secondary;
  font-size: 1.125rem;
  font-weight: 600;
}

h2 {
  font-size: .8125rem;
  font-weight: 600;
}

h3 {
  font-size: .75rem;
  font-weight: 600;
}

a {
  border-bottom: 2px solid #ffffff00;
  box-sizing: border-box;
  color: $FSX-secondary;
  font-weight: 600;
  text-decoration: none;

  &:hover {
    border-bottom: 2px solid $FSX-secondary;
    text-decoration: none;
  }
}

button, .button-link {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: .75rem;
  font-weight: bold;
  padding: .375rem 1.5rem;
}

.button-primary {
  background-color: $button-primary;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
  color: #fff;

  &:hover {
    background-color: $FSX-secondary;
    text-decoration: none;
  }

  &:disabled {
    background-color: $button-disabled;
    cursor: not-allowed;
  }
}

.button-secondary {
    background: transparent;
    border: 1px solid $button-primary;
    color: $button-primary;

    &:hover {
        border: 1px solid $FSX-secondary;
        color: $FSX-secondary;    
    }

    &:disabled {
      border: 1px solid $button-disabled;
      color: $button-disabled;    
      cursor: not-allowed;
    }
}

.button-tertiary {
  background-color: #fff;
  color: $text-tertiary;

  &:hover {
    color: #000;
  }
}

label {
  color: $text-tertiary;
  font-size: .625rem;
  font-weight: 600;
  line-height: .875rem;
  margin-left: .3125rem;
}

input, select {
  border: 1px solid $border;
}

form {
  margin-bottom: 2.5rem;

  select, input {
    border: solid 1px $border;
    border-radius: 4px;
    box-sizing: border-box;
    height: 24px;

    &:disabled {
      background-color: $button-disabled;
    }
  }

  .button-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    button {
      min-width: 90px;
      padding: 0 .75rem;
    }
  }

  @media screen and (min-width: 650px) {
    max-width: 600px;
  }
}

.form-field {
  display: flex;
  flex-direction: column;
}

.form-error p {
  color: $FSX-error;
  font-size: .625rem;
  font-weight: 500;
  margin: .3125rem 0 0 .3125rem;
}

/* Custom styling for Angular material components */

/* MatIcons */

.card:hover {
  svg path, svg circle {
    fill: $FSX-secondary !important;
  }
}

.link-button {
  cursor: pointer;
  color: $FSX-secondary;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  
  .link-button-icon {
    width: 17px; 
    height: 17px;
    margin-right: 5px;
  }

  .link-button-text {
    font-size: 12px;
  }
}


/*
  Centralized common form styles here
*/

.form-section {
  background-color: #FFF;

  h2 {
    margin-bottom: 5px;
  }

  padding:20px 0px;
  padding-right: 25px;
}

.form-border {
  border: 1px solid #efefef;
  padding: 10px;
}

.subtext {
  font-size: 10px;
  margin: 0 0 15px 0;
}

.text-bold {
  font-weight: bold;
}

.validation-form-section {
  display: flex;
  margin-bottom: 25px;

  .validation-icon-col {

    .validation-icon {
      color: $icon-success;
      transform: scale(0.5);
      margin-top: 6px;

      &.visible {
        visibility: visible;
      }
  
      &.hidden {
        visibility: hidden;
      }
    }
  }
}

.grecaptcha-badge {
  bottom: 65px !important;
}

.mat-expansion-panel {
  box-shadow: none !important;
  border: 1px solid #efefef;
}

.mat-expansion-panel-body {
  padding: 0px 15px 15px 15px !important;
}

.mat-expansion-panel-header {
  padding: 0px 15px !important;
}