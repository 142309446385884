$FSX-primary: #4767f9;
$FSX-secondary: #3b4cec;
$FSX-error: #d12f10;

$background: #f2f2f2;
$background-secondary: #fafafa;
$shadow: #cbcbcb;

$border: #bcbcc5;

$text-primary: #000;
$text-secondary: $FSX-primary;
$text-tertiary: #555555;

$icon-primary: #000;
$icon-secondary: $FSX-primary;
$icon-tertiary: #6c707f;
$icon-success: #00a889;

$button-primary: $FSX-primary;
$button-disabled: #c3c9f7;
$button-cancel-text: #7e7e7e;
